import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import apartmentsData from '../data/apartments.json';
import condosData from '../data/condominiums.json';

import Layout from '../components/Common/Layout/Layout';
import Seo from '../components/Seo';
import Hero from '../components/Common/Hero/Hero';
import PropertyGroupList from '../components/Common/PropertyGroupList/PropertyGroupList';
import PropertyInquiryModal from '../components/Common/PropertyInquiryModal/PropertyInquiryModal';

const ResidentialPage = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const propertyTitle = (title) => setModalTitle(title);

  return (
    <Layout>
      <PropertyInquiryModal
        propertyName={modalTitle}
        isOpen={isModalOpen}
        close={closeModal}
      />

      <Seo
        title="Residential Apartments for Rent in Sudbury, ON"
        description="View Luxor Management's available property rentals in Sudbury, ON."
      />

      <Hero
        background={
          <StaticImage
            src="../images/residential-hero-bg.jpg"
            layout="fullWidth"
            placeholder="blurred"
            quality="90"
            alt=""
          />
        }
      >
        <h1 className="h-page-hero-title my-4 md:my-0">Residential</h1>
      </Hero>

      <PropertyGroupList
        inquireModalOpen={openModal}
        modalTitle={propertyTitle}
        title="Apartments For Rent"
        propertyData={apartmentsData.apartments}
      />
      <PropertyGroupList
        background
        noInquire
        title="Condominiums"
        description="Fully Licensed under the Condominium Management Regulatory Authority of Ontario (CMRAO)"
        propertyData={condosData.condominiums}
      />
    </Layout>
  );
};

export default ResidentialPage;
